import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate, useParams } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import { AppContext } from "../../lib/AppProvider";
import {EventPageContext} from "./EventPageContext";

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import GroupIcon from '@mui/icons-material/Group';

import { TippingButton } from "../templates/TippingButton";

export const Event = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const { eventSlug } = useParams();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { event, setEvent, access, isCreator } = useContext(EventPageContext);
    console.log('event', event);
    console.log('access', access);
    console.log('isCreator', isCreator);
    const eventGroup = event.eventGroup;

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [prompt, setPrompt] = useState(null);

    return (<>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} 
                sx={{
                    '--Grid-borderWidth': '1px',
                    borderTop: 'var(--Grid-borderWidth) solid',
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                      borderRight: 'var(--Grid-borderWidth) solid',
                      borderBottom: 'var(--Grid-borderWidth) solid',
                      borderColor: 'divider',
                    },
                }}
            >
            <Grid size={{ xs: 12, md: 8 }}>
                <Typography>
                    {event && event.name}
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <Typography>
                    {access ? (
                        <>
                            <Box>Welcome to {event && event.name}</Box>
                            {isCreator && (
                                <Box>
                                    <Stack direction="row" spacing={2}>
                                    <Button variant="outlined" startIcon={<EditCalendarIcon />} 
                                    onClick={()=>{
                                        navigate(config.pathnames.EventUpdate.replace(":eventSlug", eventSlug));
                                    }}
                                    >
                                        Update Event
                                    </Button>
                                    <Button variant="outlined" startIcon={<GroupIcon />} 
                                        onClick={()=>{
                                            navigate(config.pathnames.Group.replace(":groupSlug", event.eventGroup.slug));
                                        }}
                                    >
                                        Group of the Event
                                    </Button>
                                    </Stack>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" startIcon={<PersonAddIcon />} 
                                onClick={()=>{
                                    navigate(config.pathnames.JoinGroup.replace(":groupSlug", eventGroup.slug));
                                }}
                                >
                                    Join {eventGroup.groupName}
                                </Button>
                                <Button variant="outlined" startIcon={<LockOpenIcon />} 
                                onClick={()=>{
                                    navigate(config.pathnames.EventGetAccess.replace(":eventSlug", eventSlug));
                                }}
                                >
                                    Get Access
                                </Button>
                            </Stack>
                        </Box>
                    )}
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <Typography>
                    4
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
                <Typography>
                    8
                </Typography>
            </Grid>
            </Grid>
        </Box>
        <TippingButton />
    </>)
}