import { Alert, List, ListItem, ListItemText, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack, TextField, IconButton, ToggleButton, ToggleButtonGroup, Snackbar, styled } from "@mui/material";
import Grid from '@mui/material/Grid2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import { httpsCallable } from "firebase/functions";

import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {GroupPageContext} from "./GroupPageContext";



const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

export const GroupSettings = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup, setCurrentGroup } = useContext(GroupPageContext);
    console.log('currentGroup', currentGroup);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);

    const [isPublic, setIsPublic] = React.useState(false);


    const [ couponValue, setCouponValue ] = React.useState(null);
    const [isFreeAccess, setIsFreeAccess] = React.useState('coupon');
    const [ existingCoupons, setExistingCoupons ] = React.useState([]);
    const [ clipboardMessage, setClipboardMessage ] = React.useState('');
    const [ couponToBeDeleted, setCouponToBeDeleted ] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [loadingFreeAccessCodeButton, setLoadingFreeAccessCodeButton] = React.useState(false);

    const handlePublicAccessChange = (event) => {
        const newStatus = event.target.checked;
        console.log('newStatus', newStatus);
        if (currentGroup.id) {
            const groupDoc = doc(firestoreInstance, 'groups', currentGroup.id);
            setDoc(groupDoc, {
                public: newStatus
            },{merge: true}).then(() => {
                //update currentGroup
                setCurrentGroup(prevState => ({
                    ...prevState,
                    public: newStatus
                }));
                setIsPublic(newStatus);
            });
        }
    };

    const [open, setOpen] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setCouponToBeDeleted(null);
        setOpenDialog(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setClipboardMessage('');
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setIsFreeAccess(newValue);
    }

    const copyToClipboard = (copyText) => {
        navigator.clipboard.writeText(copyText).then(() => {
                setClipboardMessage("Copied to Clipboard: " + copyText);
                setOpen(true);
        }).catch(err => {
                console.error('Failed to copy: ', err);
        });
    }

    const prepareDeleteCoupon = (couponCode) => {
      setCouponToBeDeleted(couponCode);
      handleClickOpenDialog();
    }

    const deleteCoupon = async () => {
      if (authUser.user) {
          try {
            const groupDeleteCouponCall = httpsCallable(functionsInstance, 'deletegroupcoupon');
            groupDeleteCouponCall({
                groupId: currentGroup.id,
                coupon: couponToBeDeleted
            }).then((res) => {
              getExistingCoupons();
              setError(null);
            });
          } catch (error) {
              setCouponToBeDeleted(null);
              setError(error);
              console.error("Error deleting coupon: ", error);
          }
      }
      handleCloseDialog();
    }

    const handleCreateFreeAccessCodeButtonClick = async () => {
      setLoadingFreeAccessCodeButton(true);
      const groupCreateCouponCall = httpsCallable(functionsInstance, 'creategroupcoupon');
      groupCreateCouponCall({
          groupId: currentGroup.id,
          //rate: discountRate,
      }).then((res) => {
          //console.log('create coupon res', res);
          setLoadingFreeAccessCodeButton(false);
          //console.log('coupon', res.data.couponCode);
          getExistingCoupons();
      }).catch(err => {
          console.log('err', err);
          setError(err.message);
          setLoadingFreeAccessCodeButton(false);
      });
    }

    const getExistingCoupons = () => {
      const groupCouponsRef = collection(firestoreInstance, '/groups/'+currentGroup.id+'/coupons');
      const groupCouponsQuery = query(groupCouponsRef, orderBy("value", "desc"));
      const querySnapshot = getDocs(groupCouponsQuery).then(snapshot => {
          if (snapshot.empty) {
            setExistingCoupons([]);
          } else {
              const coupons = [];
              snapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, " => ", doc.data());
                  coupons.push({code:doc.id, value:doc.data().value});
              });
              setExistingCoupons(coupons);
          }
      });
    }

    const handleCreateCouponButtonClick = async () => {
      setLoading(true);
      const intValue = parseInt(couponValue, 10);
      let discountRate = null;
      if (!isNaN(intValue) && intValue>=1 && intValue<100) {
          discountRate = intValue;
          //console.log('discountRate', discountRate);
          
          const groupCreateCouponCall = httpsCallable(functionsInstance, 'creategroupcoupon');
          groupCreateCouponCall({
              groupId: currentGroup.id,
              rate: discountRate,
          }).then((res) => {
              console.log('create coupon res', res);
              setLoading(false);
              console.log('coupon', res.data.couponCode);
              setCouponValue(null);
              getExistingCoupons();
          }).catch(err => {
              console.log('err', err);
              setError(err.message);
              setLoading(false);
          });
      } else {
          setError('Invalid discount rate.');
          setLoading(false);
      }
    }

    useEffect(() => {
        setError(null);
        setIsPublic(currentGroup.public);
        getExistingCoupons();
    },[auth.currentUser.uid, firestoreInstance, currentGroup]);

    return (
        <>
            {currentGroup.isGroupCreator ? (<>
                {error !== null &&
                    <Alert severity="error">{error}</Alert>
                }
                {prompt !== null &&
                    <Alert severity="info">
                        {prompt}
                    </Alert>
                }
                <Box>{currentGroup.groupName} Settings</Box>
                <Box>
                    <FormGroup>
                        <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} label="Public" labelPlacement="end" checked={isPublic} onChange={handlePublicAccessChange}/>
                    </FormGroup>
                </Box>
                {!currentGroup.public ? (<>
                    Set to public so others can view this group.
                </>) : (<>
                    Turn off public access for maintenance.
                </>)}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} 
                        sx={{
                            '--Grid-borderWidth': '1px',
                            borderTop: 'var(--Grid-borderWidth) solid',
                            borderLeft: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                            '& > div': {
                            borderRight: 'var(--Grid-borderWidth) solid',
                            borderBottom: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                            },
                        }}
                    >
                        <Grid size={{ xs: 12, md: 4 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                <>Existing Coupons</>
                            </Box>
                            <List>
                                {existingCoupons && existingCoupons.map(existingCoupon => (<>
                                    <ListItem
                                    secondaryAction={
                                        <>
                                        <IconButton edge="end" aria-label="copy" onClick={()=>{copyToClipboard(existingCoupon.code);}}>
                                        <ContentCopyIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={()=>{prepareDeleteCoupon(existingCoupon.code);}}>
                                        <DeleteIcon />
                                        </IconButton>
                                        </>
                                    }
                                    >
                                    <ListItemText
                                        primary={existingCoupon.code}
                                        secondary={existingCoupon.value==100 ? `Free Access` : `${existingCoupon.value}% Off`}
                                        sx={{ 
                                            '& .MuiListItemText-secondary': {
                                                color: existingCoupon.value == 100 ? 'red' : 'inherit'
                                            }
                                        }}
                                    />
                                    </ListItem>
                                    <Divider variant="middle" component="li" />
                                </>))}
                            </List>
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                <Box sx={{p: 2}}>
                                    <ToggleButtonGroup
                                    color="primary"
                                    value={isFreeAccess}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="Platform"
                                    >
                                        <ToggleButton value="coupon">Coupon</ToggleButton>
                                        <ToggleButton value="free-access">Free Access</ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                {isFreeAccess == 'free-access' ? (<>
                                    <Box sx={{p: 2}}>
                                        <Typography>
                                            Create Free Access Code.
                                        </Typography>
                                        <Box sx={{textAlign: 'right'}}>
                                        <LoadingButton
                                            onClick={handleCreateFreeAccessCodeButtonClick}
                                            loading={loadingFreeAccessCodeButton}
                                            loadingPosition="start"
                                            startIcon={<PlaylistAddCircleIcon />}
                                            variant="outlined"
                                        >
                                        Create Free Access Code
                                        </LoadingButton>
                                        </Box>
                                    </Box>
                                </>) : (<>
                                    <Box sx={{p: 2}}>
                                        <Typography>
                                            Enter the coupon rate (1 - 99).
                                        </Typography>
                                        <Typography>
                                            For example, entering 20 will apply a 20% discount.
                                        </Typography>
                                        <TextField id="event-coupons" label="Discount Rate" variant="outlined" placeholder="1 to 99" sx={{my:2}} fullWidth value={couponValue ? couponValue : ''} onChange={(e) => {setCouponValue(e.target.value)}}/>
                                        <Box sx={{textAlign: 'right'}}>
                                        <LoadingButton
                                            onClick={handleCreateCouponButtonClick}
                                            loading={loading}
                                            loadingPosition="start"
                                            startIcon={<PlaylistAddCircleIcon />}
                                            variant="outlined"
                                            disabled={!couponValue>0}
                                        >
                                        Create Coupon
                                        </LoadingButton>
                                        </Box>
                                    </Box>
                                </>)}
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                <>bcbe</>
                            </Box>
                        </Grid>
                    </Grid>
                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {"Confirm Coupon Deletion"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Please confirm if you would like to delete the coupon.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button onClick={deleteCoupon} autoFocus>
                            Confirm
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={open}
                        autoHideDuration={2000}
                        onClose={handleClose}
                        message={clipboardMessage}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    />
                </Box>
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
    )
}