import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid2';
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import { useParams } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { httpsCallable } from "firebase/functions";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LinkIcon from '@mui/icons-material/Link';
import CircularProgress from '@mui/material/CircularProgress';

import {GroupPageContext} from "./GroupPageContext";

export const GroupEarnings = () => {

    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;
    const { groupSlug } = useParams();

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { currentGroup } = useContext(GroupPageContext);
    const [loading, setLoading] = React.useState(true);
    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [ success, setSuccess ] = useState(false);
    console.log('currentGroup', currentGroup);

    const [ earningsData, setEarningsData ] = useState(null);
    const [ balance, setBalance ] = useState(null);
    const [ subscriptionTotal, setSubscriptionTotal ] = useState(null);
    const [ eventPaymentsTotal, setEventPaymentsTotal ] = useState(null);
    const [ tipPaymentsTotal, setTipPaymentsTotal ] = useState(null);
    const [ payoutTotal, setPayoutTotal ] = useState(null);

    dayjs.extend(localizedFormat);

    const currencyFormat = (number) => {
        const stripeAmount = number; // 123.45 USD
        const locale = 'en-US'; // Set the desired locale (e.g., 'en-US' for US English)
        const currency = 'USD'; // Set the currency code

        const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2, // Set the number of decimal places
        });

        const formattedAmount = formatter.format(stripeAmount / 100); // Divide by 100 to convert cents to dollars
        //console.log(formattedAmount); // Output: "$123.45"

        return formattedAmount;
    }

    const requestPayment = (requestTransfer) => {
        
        const requestPayment = httpsCallable(functionsInstance, 'requestpayment');
        requestPayment({
            groupId: currentGroup.id,
            groupSlug: groupSlug,
            requestTransfer: requestTransfer
        }).then((res) => {
            console.log('res', res);
            if (res.data && res.data.existing_account) {
                if (requestTransfer && res.data.transfer) {
                    // transfer
                    setBalance(null);
                    setSuccess(currencyFormat(res.data.transfer.amount) + " Transfer Successful.");
                } else if (!requestTransfer && !res.data.transfer) {
                    // open new window
                    //window.open(res.data.url, '_blank').focus();
                    window.location.href = res.data.url;
                } else if (requestTransfer && !res.data) {
                    setError('Complete setting up your Stripe account before requesting a transfer.');
                }
            } else if (res.data && res.data.url) {
                window.location.href = res.data.url;
            }
        });
        
    }

    useEffect(() => {
        if (currentGroup.id) {
            const getGroupPayments = httpsCallable(functionsInstance, 'getgrouppayments');
            getGroupPayments({
                groupId: currentGroup.id
            }).then((res) => {
                console.log('res', res);
                if (res.data) {
                    let subscriptionTotal = 0;
                    let eventPaymentTotal = 0;
                    let tipPaymentTotal = 0;
                    let payoutTotal = 0;
                    if (res.data.subscriptions) {
                        res.data.subscriptions.forEach(sub => {
                            subscriptionTotal += sub.subscriptionTotalPaid.amount - sub.subscriptionTotalPaid.fee;
                        });
                    }
                    if (res.data.eventPayments) {
                        res.data.eventPayments.forEach(eventPayment => {
                            if (eventPayment.order) {
                                eventPaymentTotal += eventPayment.order.amountReceived - (eventPayment.order.fee ? eventPayment.order.fee : 0);
                            }
                        });
                    }
                    if (res.data.tipPayments) {
                        res.data.tipPayments.forEach(tipPayment => {
                            if (tipPayment.order) {
                                tipPaymentTotal += tipPayment.order.amountReceived - (tipPayment.order.fee ? tipPayment.order.fee : 0);
                            }
                        });
                    }
                    if (res.data.payouts) {
                        res.data.payouts.forEach(payout => {
                            payoutTotal += payout.amount;
                            //console.log('time', payout.date);
                        });
                    }
                    setBalance(subscriptionTotal + eventPaymentTotal + tipPaymentTotal - payoutTotal);
                    setSubscriptionTotal(subscriptionTotal);
                    setEventPaymentsTotal(eventPaymentTotal);
                    setTipPaymentsTotal(tipPaymentTotal);
                    setPayoutTotal(payoutTotal);
                    setEarningsData(res.data);
                    setSuccess(true);
                } else {
                    setPrompt('No subscription to this group yet.');   
                    setSuccess(true);
                }
            }).catch(err => {
                setError(err.message);
            });
        }
        /*
        const groupAccessRef = collection(firestoreInstance, '/groupAccess');
        const q = query(groupAccessRef, where('groupId', '==', currentGroup.id));
        getDocs(q).then(snapshots => {
            if (snapshots.empty) {
                setLoading(false);
            } else {
                snapshots.forEach(async groupAccess => {
                    console.log('groupAccess', groupAccess.id, 'group data', groupAccess.data());
                });
                setLoading(false);
            }
        });
        */
    }, [auth.currentUser.uid, functionsInstance, firestoreInstance, groupSlug, currentGroup]);

    return (
        <>
        {success ? (
            <>
            {error !== null &&
                <Alert severity="error">{error}</Alert>
            }
            {prompt !== null &&
                <Alert severity="info">
                    {prompt}
                </Alert>
            }
            {/*success && !prompt &&
                <Alert severity="success">Retrieved your group subscriptions successfully.</Alert>
            */}
            {currentGroup.isGroupCreator ? (<>
                <Box sx={{width: '100%'}}>
                    <Typography variant="h4" gutterBottom>
                    {currentGroup.groupName} Earnings
                    </Typography>
                </Box>
                {earningsData && earningsData.connectedAccount && 
                <>
                    <Typography sx={{textAlign: 'right'}}>
                        <Button variant="text" startIcon={<LinkIcon />} onClick={()=>{requestPayment(false);}}>My Stripe Account</Button>
                    </Typography>
                </>}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} 
                        /*sx={{
                            '--Grid-borderWidth': '1px',
                            borderTop: 'var(--Grid-borderWidth) solid',
                            borderLeft: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                            '& > div': {
                            borderRight: 'var(--Grid-borderWidth) solid',
                            borderBottom: 'var(--Grid-borderWidth) solid',
                            borderColor: 'divider',
                            },
                        }}*/
                    >
                    <Grid size={{ xs: 12, md: 12 }}>
                        <Typography variant="h6" gutterBottom>Balance {balance > 0 ? currencyFormat(balance) : currencyFormat(0)}</Typography>
                        {balance > 0 && (
                            <Typography sx={{textAlign: 'right'}}>
                                <Button variant="outlined" startIcon={<AccountBalanceOutlinedIcon />} onClick={()=>{requestPayment(true);}}>{earningsData && earningsData.connectedAccount ? 'Request Payment' : 'Set up Payment Account'}</Button>
                            </Typography>
                        )}
                    </Grid>
                    <Grid size={{ xs: 12, md: 12 }}>
                        <Typography sx={{fontWeight: 'bold'}} gutterBottom>Subscriptions {currencyFormat(subscriptionTotal)}</Typography>
                        <TableContainer component={Paper} sx={{mb: 2}}>
                            <Table sx={{ width: '100%'}} size="small" aria-label="Event Payments">
                                <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Fees</TableCell>
                                    <TableCell align="right">Transactions</TableCell>
                                    <TableCell align="right">Net amount</TableCell>
                                    <TableCell align="right">Last Charge Date</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {earningsData && earningsData.subscriptions && (<>
                                    {earningsData.subscriptions.map((sub, index) => (<>
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                            <TableCell align="left"><Avatar alt={sub.userProfile.displayName} src={sub.userProfile.photoURL} sx={{ width: 24, height: 24 }} /></TableCell>
                                            <TableCell align="left">{sub.userProfile.displayName}</TableCell>
                                            <TableCell align="right">{currencyFormat(sub.subscriptionTotalPaid.amount)}</TableCell>
                                            <TableCell align="right">{currencyFormat(sub.subscriptionTotalPaid.fee)}</TableCell>
                                            <TableCell align="right">{sub.subscriptionTotalPaid.transactions}</TableCell>
                                            <TableCell align="right">{currencyFormat(sub.subscriptionTotalPaid.amount - sub.subscriptionTotalPaid.fee)}</TableCell>
                                            <TableCell align="right">@ {dayjs.unix(sub.subscriptionTotalPaid.created).format('L LT')}</TableCell>
                                        </TableRow>
                                    </>))}
                                </>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid size={{ xs: 12, md: 12 }}>
                        <Typography sx={{fontWeight: 'bold'}} gutterBottom>Event Payments {currencyFormat(eventPaymentsTotal)}</Typography>
                        <TableContainer component={Paper} sx={{mb: 2}}>
                            <Table sx={{ width: '100%' }} size="small" aria-label="Event Payments">
                                <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Event</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Fees</TableCell>
                                    <TableCell align="right">Net amount</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {earningsData && earningsData.eventPayments && (<>
                                    {earningsData.eventPayments.map((eventPayment, index) => { 
                                        if (eventPayment.order) {
                                            return(
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell align="left"><Avatar alt={eventPayment.userProfile.displayName} src={eventPayment.userProfile.photoURL} sx={{ width: 24, height: 24 }} /></TableCell>
                                                    <TableCell align="left">{eventPayment.userProfile.displayName}</TableCell>
                                                    <TableCell align="left">{eventPayment.event.name}</TableCell>
                                                    <TableCell align="right">{currencyFormat(eventPayment.order.amountReceived)}</TableCell>
                                                    <TableCell align="right">{currencyFormat(eventPayment.order.fee ? eventPayment.order.fee : 0)}</TableCell>
                                                    <TableCell align="right">{currencyFormat(eventPayment.order.amountReceived - (eventPayment.order.fee ? eventPayment.order.fee : 0))}</TableCell>
                                                    <TableCell align="right">@ {dayjs.unix(eventPayment.order.created).format('L LT')}</TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            return(
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell align="left"><Avatar alt={eventPayment.userProfile.displayName} src={eventPayment.userProfile.photoURL} sx={{ width: 24, height: 24 }} /></TableCell>
                                                    <TableCell align="left">{eventPayment.userProfile.displayName}</TableCell>
                                                    <TableCell align="left">{eventPayment.event.name}</TableCell>
                                                    <TableCell align="right">Free Access Coupon</TableCell>
                                                    <TableCell align="right">N/A</TableCell>
                                                    <TableCell align="right">N/A</TableCell>
                                                    <TableCell align="right">@ {dayjs.unix(eventPayment.created._seconds).format('L LT')}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                </>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid size={{ xs: 12, md: 12 }}>
                        <Typography sx={{fontWeight: 'bold'}} gutterBottom>Tips {currencyFormat(tipPaymentsTotal)}</Typography>
                        <TableContainer component={Paper} sx={{mb: 2}}>
                            <Table sx={{ width: '100%' }} size="small" aria-label="Event Payments">
                                <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Event</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell align="right">Fees</TableCell>
                                    <TableCell align="right">Net amount</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {earningsData && earningsData.tipPayments && (<>
                                    {earningsData.tipPayments.map((tipPayment, index) => { 
                                        if (tipPayment.order) {
                                            return(
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell align="left"><Avatar alt={tipPayment.userProfile.displayName} src={tipPayment.userProfile.photoURL} sx={{ width: 24, height: 24 }} /></TableCell>
                                                    <TableCell align="left">{tipPayment.userProfile.displayName}</TableCell>
                                                    <TableCell align="left">{tipPayment.event.name}</TableCell>
                                                    <TableCell align="right">{currencyFormat(tipPayment.order.amountReceived)}</TableCell>
                                                    <TableCell align="right">{currencyFormat(tipPayment.order.fee ? tipPayment.order.fee : 0)}</TableCell>
                                                    <TableCell align="right">{currencyFormat(tipPayment.order.amountReceived - (tipPayment.order.fee ? tipPayment.order.fee : 0))}</TableCell>
                                                    <TableCell align="right">@ {dayjs.unix(tipPayment.order.created).format('L LT')}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                </>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid size={{ xs: 12, md: 12 }}>
                        <Typography sx={{fontWeight: 'bold'}} gutterBottom>Payouts {currencyFormat(payoutTotal)}</Typography>
                        <TableContainer component={Paper} sx={{mb: 2}}>
                            <Table sx={{ width: '100%' }} size="small" aria-label="Payouts">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {earningsData && earningsData.payouts && (<>
                                    {earningsData.payouts.map((payout, index) => { 
                                        if (payout) {
                                            return(
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell align="right">{currencyFormat(payout.amount)}</TableCell>
                                                    <TableCell align="right">@ {dayjs.unix(payout.date).format('L LT')}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                </>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    </Grid>
                </Box>
            </>) : (<>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Page Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            </>)}
        </>
        ) : (
            <Box sx={{width: '100%', p: 5, textAlign:'center'}}><CircularProgress /></Box>
        )}
        </>
    )
}