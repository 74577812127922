import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, TextField, InputAdornment } from "@mui/material";
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FavoriteIcon from '@mui/icons-material/Favorite';
import React, { useContext, useState, useRef } from "react";
import { AuthContext } from "../../lib/Auth";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { AppContext } from "../../lib/AppProvider";
import {EventPageContext} from "../event/EventPageContext";
import { httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../payment/CheckoutForm";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const TippingButton = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { event, setEvent, access, setAccess } = useContext(EventPageContext);
    console.log('event', event);
    const eventGroup = event.eventGroup;

    const ref = React.useRef(null);
    const [loadingCC, setLoadingCC] = useState(false);
    const [payButtonDisabled, setPayButtonDisabled] = useState(false);
    const [showPayMethod, setShowPayMethod] = React.useState(false);
    const [tipAmount, setTipAmount] = useState(null);
    const [amountTextMessage, setAmountTextMessage] = useState(null);
    const amountInput = useRef(null);

    const theme = useTheme();
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const [open, setOpen] = React.useState(false);
    
    const handleClose = () => {
        setOpen(false);
    };

    const payNow = () => {
        //console.log('tipAmount', tipAmount);
        setLoadingCC(true);
        setPayButtonDisabled(true);
        const createTippingSession = httpsCallable(functionsInstance, 'createtippingsession');
        createTippingSession({ items: [{ amount: tipAmount }], groupId: event.group_id, eventId: event.id}).then((res) => {
            //console.log('res ', res);
            setClientSecret(res.data.clientSecret)
            setShowPayMethod(true);
            setLoadingCC(false);
        }).catch(error => {
            //setError(error.message);
            //setProcessing(false);
            console.log('error ', error);
        });
    }

    function isCurrency(str) {
        const regex = /^(?=.*?\d)^(\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{0,2})?)$/;
        return regex.test(str);
    }

    const inputTipAmount = (inputAmount) => {
        if (inputAmount === '.' || inputAmount === '') {
            setAmountTextMessage(null);
            setTipAmount(null);
        } else if (isCurrency(inputAmount)) {
            setAmountTextMessage(null);
            setTipAmount(parseFloat(inputAmount).toFixed(2));
        } else {
            setAmountTextMessage('Invalid Amount.');
            setTipAmount(null);
        }
    }

    function formatCurrency(amount) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    }

    const stripePromise = loadStripe("pk_test_51L44EYAZ8dJdmsUfoGp1AyJ5Mm6jQYpGlUDKUSPdCptoTAyVBmQ03bwhkM0jIh2sgLHy9e65MeQK6WpqAjp736SO00aV0psDqO");
    const [clientSecret, setClientSecret] = useState("");
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const paymentFormIsReady = () => {
        
    }
    
    const paymentCallback = () => {
        setShowPayMethod(false);
        setTipAmount(null);
        amountInput.current.value = '';
    }

    return (
        <>
        <Zoom
          key='primary'
          in={true}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${transitionDuration.exit}ms`,
          }}
          unmountOnExit
        >
          <Fab sx={fabStyle} aria-label='Tip' color='primary' onClick={handleClickOpen}>
            <FavoriteBorderIcon/>
          </Fab>
        </Zoom>
        <Dialog
            fullWidth
            maxWidth="md"
            sx={{
            "& .MuiDialog-paper": {
                margin: 0,
                width: "100%",
                'border-radius': 0,
                position: "absolute",
                bottom:0,
                height: "80vh"
            }
            }}
            BackdropProps={{
                invisible: true
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                Tipping
                <ExpandMoreOutlinedIcon onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 18,
                }}
                />
            </DialogTitle>
            <DialogContent ref={ref}>
            <DialogContentText id="tipping-dialog">
                <Typography sx={{p: 2}}>
                    <TextField
                    error = {amountTextMessage !== null}
                    label="Amount"
                    id="outlined-start-adornment"
                    sx={{ width: '100%' }}
                    variant="standard"
                    slotProps={{
                        input: {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        },
                    }}
                    helperText={amountTextMessage}
                    onChange={e => inputTipAmount(e.target.value)}
                    inputRef={amountInput}
                    />
                </Typography>
                <Typography sx={{p:2}}>
                {showPayMethod ? (
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm paymentCallback={paymentCallback} paymentFormIsReady={paymentFormIsReady}/>
                    </Elements>
                    ) : (<>
                        {tipAmount && (<>
                            <LoadingButton variant="outlined" startIcon={<CreditCardIcon />} onClick={payNow} loading={loadingCC} loadingPosition="start" disabled={payButtonDisabled} sx={{minWidth: '200px'}}>
                                Tip {formatCurrency(tipAmount)}
                            </LoadingButton>
                        </>)}
                    </>)
                }
                </Typography>
            </DialogContentText>
            </DialogContent>
        </Dialog>
        </>
    )
}