import { Alert, List, ListItem, ListItemText, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack, TextField, IconButton, ToggleButton, ToggleButtonGroup, Snackbar } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../lib/Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate, useParams } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import { AppContext } from "../../lib/AppProvider";
import {EventPageContext} from "./EventPageContext";
import { httpsCallable } from "firebase/functions";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const EventSettings = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const { eventSlug } = useParams();

    const { config } = useContext(AppContext);
    const pathnames = config.pathnames;

    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { event, setEvent, access, isCreator } = useContext(EventPageContext);
    console.log('event', event);
    console.log('access', access);
    console.log('isCreator', isCreator);
    const eventGroup = event.eventGroup;

    const [loading, setLoading] = React.useState(false);
    const [loadingFreeAccessCodeButton, setLoadingFreeAccessCodeButton] = React.useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [prompt, setPrompt] = useState(null);
    const [isFreeAccess, setIsFreeAccess] = React.useState('coupon');

    const [ couponValue, setCouponValue ] = React.useState(null);
    const [ existingCoupons, setExistingCoupons ] = React.useState([]);
    const [ clipboardMessage, setClipboardMessage ] = React.useState('');
    const [ couponToBeDeleted, setCouponToBeDeleted ] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setCouponToBeDeleted(null);
        setOpenDialog(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setClipboardMessage('');
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setIsFreeAccess(newValue);
    }

    const copyToClipboard = (copyText) => {
        navigator.clipboard.writeText(copyText).then(() => {
                setClipboardMessage("Copied to Clipboard: " + copyText);
                setOpen(true);
        }).catch(err => {
                console.error('Failed to copy: ', err);
        });
    }

    const prepareDeleteCoupon = (couponCode) => {
        setCouponToBeDeleted(couponCode);
        handleClickOpenDialog();
    }

    const deleteCoupon = async () => {
        if (authUser.user) {
            try {
                const eventDeleteCouponCall = httpsCallable(functionsInstance, 'deleteeventcoupon');
                eventDeleteCouponCall({
                    eventId: event.id,
                    coupon: couponToBeDeleted
                }).then((res) => {
                  getExistingCoupons();
                  setError(null);
                });
              } catch (error) {
                  setCouponToBeDeleted(null);
                  setError(error);
                  console.error("Error deleting coupon: ", error);
              }
        }
        handleCloseDialog();
    }

    const handleCreateFreeAccessCodeButtonClick = async () => {
        setLoadingFreeAccessCodeButton(true);
        if (authUser.user) {
            const eventCreateCouponCall = httpsCallable(functionsInstance, 'createeventcoupon');
            eventCreateCouponCall({
                eventId: event.id,
                //rate: discountRate,
            }).then((res) => {
                console.log('create coupon res', res);
                setLoadingFreeAccessCodeButton(false);
                console.log('coupon', res.data.couponCode);
                setCouponValue(null);
                getExistingCoupons();
            }).catch(err => {
                console.log('err', err);
                setError(err.message);
                setLoadingFreeAccessCodeButton(false);
            });
        }
    }

    const handleCreateCouponButtonClick = async () => {
        setLoading(true);
        const intValue = parseInt(couponValue, 10);
        let discountRate = null;
        if (!isNaN(intValue) && intValue>=1 && intValue<100) {
            discountRate = intValue;
            //console.log('discountRate', discountRate);
            
            const eventCreateCouponCall = httpsCallable(functionsInstance, 'createeventcoupon');
            eventCreateCouponCall({
                eventId: event.id,
                rate: discountRate,
            }).then((res) => {
                console.log('create coupon res', res);
                setLoading(false);
                console.log('coupon', res.data.couponCode);
                setCouponValue(null);
                getExistingCoupons();
            }).catch(err => {
                console.log('err', err);
                setError(err.message);
                setLoading(false);
            });
        } else {
            setError('Invalid discount rate.');
            setLoading(false);
        }
    }

    const getExistingCoupons = () => {
        const eventCouponsRef = collection(firestoreInstance, '/events/'+event.id+'/coupons');
        const eventCouponsQuery = query(eventCouponsRef, orderBy("value", "desc"));
        const querySnapshot = getDocs(eventCouponsQuery).then(snapshot => {
            if (snapshot.empty) {
                
            } else {
                const coupons = [];
                snapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    coupons.push({code:doc.id, value:doc.data().value});
                });
                setExistingCoupons(coupons);
            }
        });
    }
    useEffect(() => {
        getExistingCoupons();
    }, [auth.currentUser.uid, functionsInstance, firestoreInstance, eventSlug, event]);

    return (<>
        {isCreator ? (
            <Box sx={{ flexGrow: 1 }}>
                <Grid size={{ xs: 12, md: 12 }}>
                {error !== null &&
                    <Alert severity="error">{error}</Alert>
                }
                {prompt !== null &&
                    <Alert severity="info">
                        {prompt}
                    </Alert>
                }
                </Grid>
                <Grid container spacing={0} 
                    sx={{
                        '--Grid-borderWidth': '1px',
                        borderTop: 'var(--Grid-borderWidth) solid',
                        borderLeft: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        '& > div': {
                        borderRight: 'var(--Grid-borderWidth) solid',
                        borderBottom: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        },
                    }}
                >
                <Grid size={{ xs: 12, md: 8 }}>
                    <Typography>
                        {event && event.name}
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" startIcon={<ArrowBackIcon />} 
                            onClick={()=>{
                                navigate(config.pathnames.Event.replace(":eventSlug", eventSlug));
                            }}
                        >
                            Back to Event
                        </Button>
                    </Stack>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Typography>
                        Existing Coupons
                    </Typography>
                    <List>
                        {existingCoupons && existingCoupons.map(existingCoupon => (<>
                            <ListItem
                            secondaryAction={
                                <>
                                <IconButton edge="end" aria-label="copy" onClick={()=>{copyToClipboard(existingCoupon.code);}}>
                                <ContentCopyIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={()=>{prepareDeleteCoupon(existingCoupon.code);}}>
                                <DeleteIcon />
                                </IconButton>
                                </>
                            }
                            >
                            <ListItemText
                                primary={existingCoupon.code}
                                secondary={existingCoupon.value==100 ? `Free Access` : `${existingCoupon.value}% Off`}
                                sx={{ 
                                    '& .MuiListItemText-secondary': {
                                        color: existingCoupon.value == 100 ? 'red' : 'inherit'
                                    }
                                }}
                            />
                            </ListItem>
                            <Divider variant="middle" component="li" />
                        </>))}
                    </List>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Grid container spacing={0}>
                        <Grid size={12} sx={{width: '100%'}}>
                            <Box sx={{p: 2}}>
                                <ToggleButtonGroup
                                color="primary"
                                value={isFreeAccess}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                                >
                                    <ToggleButton value="coupon">Coupon</ToggleButton>
                                    <ToggleButton value="free-access">Free Access</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                            {isFreeAccess == 'free-access' ? (<>
                                <Box sx={{p: 2}}>
                                    <Typography>
                                        Create Free Access Code.
                                    </Typography>
                                    <Box sx={{textAlign: 'right'}}>
                                    <LoadingButton
                                        onClick={handleCreateFreeAccessCodeButtonClick}
                                        loading={loadingFreeAccessCodeButton}
                                        loadingPosition="start"
                                        startIcon={<PlaylistAddCircleIcon />}
                                        variant="outlined"
                                    >
                                    Create Free Access Code
                                    </LoadingButton>
                                    </Box>
                                </Box>
                            </>) : (<>
                                <Box sx={{p: 2}}>
                                    <Typography>
                                        Enter the coupon rate (1 - 99).
                                    </Typography>
                                    <Typography>
                                        For example, entering 20 will apply a 20% discount.
                                    </Typography>
                                    <TextField id="event-coupons" label="Discount Rate" variant="outlined" placeholder="1 to 99" sx={{my:2}} fullWidth value={couponValue ? couponValue : ''} onChange={(e) => {setCouponValue(e.target.value)}}/>
                                    <Box sx={{textAlign: 'right'}}>
                                    <LoadingButton
                                        onClick={handleCreateCouponButtonClick}
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<PlaylistAddCircleIcon />}
                                        variant="outlined"
                                        disabled={!couponValue>0}
                                    >
                                    Create Coupon
                                    </LoadingButton>
                                    </Box>
                                </Box>
                            </>)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>

                </Grid>
                </Grid>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Confirm Coupon Deletion"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Please confirm if you would like to delete the coupon.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={deleteCoupon} autoFocus>
                        Confirm
                    </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    message={clipboardMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
            </Box>
        ) : (<>
            Permission Needed.
        </>)}
    </>)
}