import firebaseConfig from './firebaseConfig.json';
import paymentConfig from './paymentConfig.json';
import authMethods from "./authMethods.json";
import pathnames from './lib/pathnames.json';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContext, AuthProvider, AuthRoutes } from "./lib/Auth";
import {AppProvider} from './lib/AppProvider';
import CoverPage from './component/CoverPage';
import {UserProfile, UserUpdateName, UserUpdatePassword, UserDelete} from './component/user';
import {Group, ListGroups, CreateGroup, GroupSettings, GroupInvoices, GroupListUsers, GroupChangePlan, UpdateBillingDetails, ManagePaymentMethods, CancelSubscription, JoinGroup, GroupMembershipChangePlan, GroupMembershipInvoices, CancelGroupSubscription, GroupMembershipManagePaymentMethods, UpdateMembershipBillingDetails, GroupEarnings, GroupEvents} from './component/group';
import {AdminPanel, AdminWebsiteUsers, AdminCoupons, AdminEarnings} from './component/admin';
import {Event, ListEvents, CreateEvent, EventGetAccess, EventUpdate, EventSettings} from './component/event';
//import CreateEvent from './component/event/CreateEvent';
import {AppTemplate, PublicTemplate} from './component/templates';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SignIn, SignUp, ResetPassword } from "./component/auth";
import {GroupPageProvider} from "./component/group/GroupPageContext";
import {GroupMenuProvider} from "./component/group/GroupMenuContext";
import {EventPageProvider} from "./component/event/EventPageContext";
import {AdminPageProvider} from "./component/admin/AdminPageContext";
import './App.css';

import Avatar from '@mui/material/Avatar';
import { CircularProgress, Box } from '@mui/material';

const Logo = ({size}) => {
  let cpSize = 32;
  switch(size){
      case "small":
          cpSize = 32;
          break;
      case "medium":
          cpSize = 48;
          break;
      case "large":
          cpSize = 64;
          break;
      default:
          cpSize = 32;
          break;
  }
  return (
      <Avatar
        alt=""
        src="/logo192.png"
        sx={{ width: cpSize, height: cpSize }}
      />
  );
}

const Loader = ({size}) => {
  let cpSize = "60px";
  switch(size){
      case "small":
          cpSize = "30px";
          break;
      case "medium":
          cpSize = "35px";
          break;
      case "large":
          cpSize = "60px";
          break;
      default:
          cpSize = "35px";
          break;
  }
  return (
      <Box sx={{ mt: 5, display: 'flex', justifyContent: "center", alignItems: "center"}}>
          <CircularProgress color="warning" size={cpSize} />
          <div style={{position: "absolute" }}>
              <Logo size={size} />
          </div>
      </Box>
  );
}

function App() {
  const config = {
		brand: "BCBE",
    firebaseConfig: firebaseConfig,
    pathnames: pathnames,
    authProviders: authMethods,
    paymentConfig: paymentConfig,
    defaultGroupCover: "https://firebasestorage.googleapis.com/v0/b/bcbe-f2a8e.appspot.com/o/images%2F1-planet-illustration-updated.width-1320.jpg?alt=media&token=b3f10097-ef52-4fcb-9325-0f250c6d5b78",
    singleAccessPrice: 5
	}

  const themeLight = createTheme({
    palette: {
      primary: {
          main: "#181818"
      }
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    }
  });

  return (
    <ThemeProvider theme={themeLight}>
    <AppProvider config={config}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<AuthRoutes loader={<Loader size="large" />} />} >

              <Route element={<GroupMenuProvider />}>
                <Route element={<AppTemplate logo={<Logo size="small" />} />}>
                  <Route exact path="/" element={<CoverPage />} />
                  <Route exact path={pathnames.ListGroups} element={<ListGroups /> } />
                  <Route exact path={pathnames.ListEvents} element={<ListEvents /> } />

                  <Route path={pathnames.Group} element={<GroupPageProvider />} >
                    <Route exact path={pathnames.Group} element={<Group /> } />
                    <Route exact path={pathnames.GroupEvents} element={<GroupEvents /> } />
                    <Route exact path={pathnames.GroupSettings} element={<GroupSettings /> } />
                    <Route exact path={pathnames.GroupInvoices} element={<GroupInvoices /> } />
                    <Route exact path={pathnames.GroupListUsers} element={<GroupListUsers /> } />
                    <Route exact path={pathnames.GroupEarnings} element={<GroupEarnings /> } />
                    <Route exact path={pathnames.GroupChangePlan} element={<GroupChangePlan /> } />
                    <Route exact path={pathnames.JoinGroup} element={<JoinGroup /> } />
                    <Route exact path={pathnames.UpdateBillingDetails} element={<UpdateBillingDetails /> } />
                    <Route exact path={pathnames.CancelSubscription} element={<CancelSubscription /> } />
                    <Route exact path={pathnames.ManagePaymentMethods} element={<ManagePaymentMethods /> } />
                    <Route exact path={pathnames.GroupMembershipChangePlan} element={<GroupMembershipChangePlan /> } />
                    <Route exact path={pathnames.GroupMembershipInvoices} element={<GroupMembershipInvoices /> } />
                    <Route exact path={pathnames.CancelGroupSubscription} element={<CancelGroupSubscription /> } />
                    <Route exact path={pathnames.GroupMembershipManagePaymentMethods} element={<GroupMembershipManagePaymentMethods /> } />
                    <Route exact path={pathnames.UpdateMembershipBillingDetails} element={<UpdateMembershipBillingDetails /> } />
                    <Route exact path={pathnames.CreateEvent} element={<CreateEvent />} />
                  </Route>

                  <Route path={pathnames.Event} element={<EventPageProvider />} >
                    <Route exact path={pathnames.Event} element={<Event /> } />
                    <Route exact path={pathnames.EventGetAccess} element={<EventGetAccess /> } />
                    <Route exact path={pathnames.EventUpdate} element={<EventUpdate /> } />
                    <Route exact path={pathnames.EventSettings} element={<EventSettings /> } />
                  </Route>

                  <Route exact path={pathnames.UserProfile} element={<UserProfile />} />
                  <Route exact path={pathnames.UserUpdateName} element={<UserUpdateName />} />
                  <Route exact path={pathnames.UserUpdatePassword} element={<UserUpdatePassword />} />
                  <Route exact path={pathnames.UserDelete} element={<UserDelete />} />
                  <Route exact path={pathnames.CreateGroup} element={<CreateGroup />} />

                  <Route path={pathnames.Admin} element={<AdminPageProvider />} >
                    <Route exact path={pathnames.Admin} element={<AdminPanel />} />
                    <Route exact path={pathnames.AdminWebsiteUsers} element={<AdminWebsiteUsers />} />
                    <Route exact path={pathnames.AdminCoupons} element={<AdminCoupons />} />
                    <Route exact path={pathnames.AdminEarnings} element={<AdminEarnings />} />
                  </Route>
                </Route>
              </Route>

						</Route>
						<Route element={<PublicTemplate />}>
							<Route path={pathnames.SignIn} element={
								<SignIn
									logo={<Logo size="large" />}
								/>
							} />
              <Route path={pathnames.SignUp} element={
								<SignUp
									logo={<Logo size="large" />}
								/>
							} />
              <Route path={pathnames.ResetPassword} element={
								<ResetPassword
									logo={<Logo size="large" />}
								/>
							} />
						</Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </AppProvider>
    </ThemeProvider>
  );
}

export default App;
