import { Alert, List, ListItem, ListItemText, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack, TextField, IconButton, ToggleButton, ToggleButtonGroup, Snackbar, Collapse } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../lib/Auth";
import { AppContext } from "../../lib/AppProvider";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import { getAuth, sendEmailVerification } from "firebase/auth";
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';

import {AdminPageContext} from "./AdminPageContext";

export const AdminEarnings = () => {
    const auth = getAuth();
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(AppContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);

    const [subscriptionsData, setSubscriptionsData] = useState(null);
    const [groupSubscriptionsData, setGroupSubscriptionsData] = useState(null);
    const [eventAccessData, setEventAccessData] = useState(null);
    const [tipsData, setTipsData] = useState(null);

    const { admin } = useContext(AdminPageContext);

    //console.log('authUser ', authUser);
    console.log('admin', admin);

    const currencyFormat = (number) => {
        const stripeAmount = number; // 123.45 USD
        const locale = 'en-US'; // Set the desired locale (e.g., 'en-US' for US English)
        const currency = 'USD'; // Set the currency code

        const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2, // Set the number of decimal places
        });

        const formattedAmount = formatter.format(stripeAmount / 100); // Divide by 100 to convert cents to dollars
        //console.log(formattedAmount); // Output: "$123.45"

        return formattedAmount;
    }
      
    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
      
        return (
          <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                {row.group.groupName}
              </TableCell>
              <TableCell align="right">{row.subscription.plan}</TableCell>
              <TableCell align="right">
                {row.subscription.price ? (<>
                    {row.subscription.currency}{row.subscription.price}/{row.subscription.paymentCycle}
                </>) : (<>
                    {row.subscription.currency}0/{row.subscription.paymentCycle}
                </>)}
                </TableCell>
              <TableCell align="right">{row.invoices.length}</TableCell>
              <TableCell align="right">
                    {currencyFormat(row.invoices.reduce((sum, invoice) => sum + invoice.fee ? invoice.fee : 0, 0))}
              </TableCell>
              <TableCell align="right">
              {currencyFormat(row.invoices.reduce((sum, invoice) => sum + invoice.total ? invoice.total : 0, 0) - row.invoices.reduce((sum, invoice) => sum + invoice.fee ? invoice.fee : 0, 0))}
              </TableCell>
              <TableCell align="right">{dayjs.unix(row.subscription.subscriptionCurrentPeriodEnd).format('L LT')}</TableCell>
              <TableCell sx={{color: row.subscription.subscriptionStatus == 'active' ? 'green' : 'red'}} align="right">{row.subscription.subscriptionStatus}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Invoices
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{fontWeight: 'bold'}}>Date</TableCell>
                          <TableCell sx={{fontWeight: 'bold'}}>Price</TableCell>
                          <TableCell sx={{fontWeight: 'bold'}}>Coupon</TableCell>
                          <TableCell sx={{fontWeight: 'bold'}}>Paid</TableCell>
                          <TableCell sx={{fontWeight: 'bold'}} align="right">Fee</TableCell>
                          <TableCell sx={{fontWeight: 'bold'}} align="right">Net Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.invoices && row.invoices.map((invoiceRow) => (
                          <TableRow key={invoiceRow.created}>
                            <TableCell component="th" scope="row">
                              {dayjs.unix(invoiceRow.created).format('L LT')}
                            </TableCell>
                            <TableCell>{currencyFormat(invoiceRow.subTotal)}</TableCell>
                            <TableCell>{invoiceRow.discount ? `${invoiceRow.discount}% Off ` : ''}</TableCell>
                            <TableCell>{currencyFormat(invoiceRow.total)}</TableCell>
                            <TableCell align="right">{invoiceRow.fee ? currencyFormat(invoiceRow.fee) : currencyFormat(0)}</TableCell>
                            <TableCell align="right">
                              {currencyFormat(invoiceRow.total - invoiceRow.fee ? invoiceRow.fee : 0)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
    }

    useEffect(() => {
        if (admin == 'super') {
            const getWebsiteEarnings = httpsCallable(functionsInstance, 'getwebsiteearnings');
            getWebsiteEarnings().then((res) => {
                console.log('res', res);
                if (res.data) {
                    if (res.data.subscriptions) {
                        setSubscriptionsData(res.data.subscriptions);
                    }
                    if (res.data.groupSubscriptions) {
                        setGroupSubscriptionsData(res.data.groupSubscriptions);
                    }
                    if (res.data.eventAccess) {
                        setEventAccessData(res.data.eventAccess);
                    }
                    if (res.data.tips) {
                        setTipsData(res.data.tips);
                    }
                }
                setLoaded(true);
            });
        } else {
            setLoaded(true);
        }
    }, [auth.currentUser.uid, admin, functionsInstance, firestoreInstance]);
    
    return (<>
        {loaded ? (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} 
                    sx={{
                        '--Grid-borderWidth': '1px',
                        borderTop: 'var(--Grid-borderWidth) solid',
                        borderLeft: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        '& > div': {
                        borderRight: 'var(--Grid-borderWidth) solid',
                        borderBottom: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        },
                    }}
                >
                    <Grid size={{ xs: 12, md: 12 }}>
                        {error !== null &&
                            <Alert severity="error">{error}</Alert>
                        }
                        {prompt !== null &&
                            <Alert severity="info">
                                {prompt}
                            </Alert>
                        }
                    </Grid>
                    <Grid size={{ xs: 12, md: 8 }}>
                        <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            {admin == 'super' && (
                                    <>
                                    <Grid container spacing={0}>
                                        <Grid xs={12} md={12}>
                                            <Box sx={{p:2}}>
                                                BCBE Website Earnings
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    </>
                            )}
                            {admin == 'manager' && (
                                <Box sx={{height: '70vh', p: 10, alignContent: 'center', textAlign: 'center'}}>
                                    Business Co-operator Access Permission
                                </Box>
                            )}
                            {admin == '' && (
                                <>
                                    Access Permission is required.
                                </>
                            )}
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="left" alignItems="center">
                        <Typography>
                        Access Level - {admin}    
                        </Typography>
                    </Grid>
                    {admin == 'super' && (
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={0} 
                                sx={{
                                    '--Grid-borderWidth': '1px',
                                    borderTop: 'var(--Grid-borderWidth) solid',
                                    borderLeft: 'var(--Grid-borderWidth) solid',
                                    borderColor: 'divider',
                                    '& > div': {
                                    borderRight: 'var(--Grid-borderWidth) solid',
                                    borderBottom: 'var(--Grid-borderWidth) solid',
                                    borderColor: 'divider',
                                    },
                                }}
                            >
                                <Grid size={{ xs: 12, md: 12 }}>
                                    <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                        <Typography  variant="h6" gutterBottom>Subscriptions from Creators</Typography>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="collapsible table">
                                                <TableHead>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell sx={{fontWeight: 'bold'}}>Group</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}} align="right">Plan</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}} align="right">Price</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}} align="right">Transactions</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}} align="right">Fees</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}} align="right">Net amount</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}} align="right">Current Period Ends</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}} align="right">Status</TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {subscriptionsData && subscriptionsData.map((sub) => (
                                                    <Row key={sub.subscriptionId} row={sub} />
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Grid>
                                <Grid size={{ xs: 12, md: 12 }}>
                                    <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                        <Typography  variant="h6" gutterBottom>Group Subscriptions</Typography>
                                        {groupSubscriptionsData && (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ width: '100%' }} aria-label="group subscriptions table">
                                                <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Subscriptions Total</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Earnings after Discount</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Fees</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Net Amount</TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow
                                                    key='subs-stats'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                    <TableCell>{currencyFormat(groupSubscriptionsData.subTotal)}</TableCell>
                                                    <TableCell>{currencyFormat(groupSubscriptionsData.total)}</TableCell>
                                                    <TableCell>{currencyFormat(groupSubscriptionsData.fee)}</TableCell>
                                                    <TableCell>{currencyFormat(groupSubscriptionsData.total - groupSubscriptionsData.fee)} </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                        <Typography  variant="h6" gutterBottom>Events Access</Typography>
                                        {eventAccessData && (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ width: '100%' }} aria-label="group subscriptions table">
                                                <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Earnings after Discount</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Fees</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Net Amount</TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow
                                                    key='subs-stats'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                    <TableCell>{currencyFormat(eventAccessData.amountReceived)}</TableCell>
                                                    <TableCell>{currencyFormat(eventAccessData.fee)}</TableCell>
                                                    <TableCell>{currencyFormat(eventAccessData.amountReceived - eventAccessData.fee)} </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                    <Typography  variant="h6" gutterBottom>Tips</Typography>
                                    {tipsData && (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ width: '100%' }} aria-label="group subscriptions table">
                                                <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Tips Amount</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Fees</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold'}}>Net Amount</TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow
                                                    key='subs-stats'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                    <TableCell>{currencyFormat(tipsData.amountReceived)}</TableCell>
                                                    <TableCell>{currencyFormat(tipsData.fee)}</TableCell>
                                                    <TableCell>{currencyFormat(tipsData.amountReceived - tipsData.fee)} </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Box>
        ) : (
            <Box sx={{width: '100%', p: 5, textAlign:'center'}}><CircularProgress /></Box>
        )}
    </>)
}
