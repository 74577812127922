import { Alert, List, ListItem, ListItemText, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack, TextField, IconButton, ToggleButton, ToggleButtonGroup, Snackbar } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../lib/Auth";
import { AppContext } from "../../lib/AppProvider";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import CircularProgress from '@mui/material/CircularProgress';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';

import {AdminPageContext} from "./AdminPageContext";

export const AdminPanel = () => {
    const auth = getAuth();
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(AppContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);

    const { admin } = useContext(AdminPageContext);

    //console.log('authUser ', authUser);
    console.log('admin', admin);

    const [subscriptionsData, setSubscriptionsData] = useState(null);
    const [subsXAxisData, setSubsXAxisData] = useState(null);
    const [subsSeriesData, setSubsSeriesData] = useState(null);

    const [groupSubscriptionsData, setGroupSubscriptionsData] = useState(null);
    const [groupSubsXAxisData, setGroupSubsXAxisData] = useState(null);
    const [groupSubsSeriesData, setGroupSubsSeriesData] = useState(null);

    const [eventAccessData, setEventAccessData] = useState(null);
    const [eventAccessXAxisData, setEventAccessXAxisData] = useState(null);
    const [eventAccessSeriesData, setEventAccessSeriesData] = useState(null);

    const [tipsData, setTipsData] = useState(null);
    const [tipsXAxisData, setTipsXAxisData] = useState(null);
    const [tipsSeriesData, setTipsSeriesData] = useState(null);
    
    const currencyFormat = (number) => {
        const stripeAmount = number; // 123.45 USD
        const locale = 'en-US'; // Set the desired locale (e.g., 'en-US' for US English)
        const currency = 'USD'; // Set the currency code

        const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2, // Set the number of decimal places
        });

        const formattedAmount = formatter.format(stripeAmount / 100); // Divide by 100 to convert cents to dollars
        //console.log(formattedAmount); // Output: "$123.45"

        return formattedAmount;
    }

    useEffect(() => {
        if (admin == 'super') {
            const getWebsiteEarningDetails = httpsCallable(functionsInstance, 'getwebsiteearningdetails');
            getWebsiteEarningDetails().then((res) => {
                console.log('res', res);
                if (res.data) {
                    if (res.data.subscriptions) {
                        setSubscriptionsData(res.data.subscriptions);

                        const subs = res.data.subscriptions;

                        // Convert object to array of key-value pairs
                        const sortedEntries = Object.entries(subs).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

                        // Convert back to object
                        const sortedSubs = Object.fromEntries(sortedEntries);
                        const subsXAxis = [];
                        const subsSeries = [];
                        for (let [key, value] of Object.entries(sortedSubs)) {
                            //console.log(`${key}: ${value}`);
                            subsXAxis.push(key);
                            let amount = 0;
                            for (let i = 0; i < value.length; i++) {
                                amount += value[i].total - (value[i].fee ? value[i].fee : 0);
                            }
                            subsSeries.push((amount/100).toFixed(2));
                        }
                        console.log('subsXAxis', subsXAxis, 'subsSeries', subsSeries);
                        setSubsXAxisData(subsXAxis);
                        setSubsSeriesData(subsSeries);
                    }
                    if (res.data.groupSubscriptions) {
                        setGroupSubscriptionsData(res.data.groupSubscriptions);

                        const groupSubs = res.data.groupSubscriptions;

                        // Convert object to array of key-value pairs
                        const sortedEntries = Object.entries(groupSubs).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

                        // Convert back to object
                        const sortedGroupSubs = Object.fromEntries(sortedEntries);
                        const groupSubsXAxis = [];
                        const groupSubsSeries = [];
                        for (let [key, value] of Object.entries(sortedGroupSubs)) {
                            //console.log(`${key}: ${value}`);
                            groupSubsXAxis.push(key);
                            let amount = 0;
                            for (let i = 0; i < value.length; i++) {
                                amount += value[i].total - (value[i].fee ? value[i].fee : 0);
                            }
                            groupSubsSeries.push((amount/100).toFixed(2));
                        }
                        console.log('groupSubsXAxis', groupSubsXAxis, 'groupSubsSeries', groupSubsSeries);
                        setGroupSubsXAxisData(groupSubsXAxis);
                        setGroupSubsSeriesData(groupSubsSeries);
                    }
                    if (res.data.eventAccess) {
                        setEventAccessData(res.data.eventAccess);

                        const eventAccess = res.data.eventAccess;

                        // Convert object to array of key-value pairs
                        const sortedEntries = Object.entries(eventAccess).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

                        // Convert back to object
                        const sortedEventAccess = Object.fromEntries(sortedEntries);
                        const eventAccessXAxis = [];
                        const eventAccessSeries = [];
                        for (let [key, value] of Object.entries(sortedEventAccess)) {
                            //console.log(`${key}: ${value}`);
                            eventAccessXAxis.push(key);
                            let amount = 0;
                            for (let i = 0; i < value.length; i++) {
                                amount += value[i].amountReceived - (value[i].fee ? value[i].fee : 0);
                            }
                            eventAccessSeries.push((amount/100).toFixed(2));
                        }
                        console.log('eventAccessXAxis', eventAccessXAxis, 'eventAccessSeries', eventAccessSeries);
                        setEventAccessXAxisData(eventAccessXAxis);
                        setEventAccessSeriesData(eventAccessSeries);
                    }
                    if (res.data.tips) {
                        setTipsData(res.data.tips);

                        const tips = res.data.tips;

                        // Convert object to array of key-value pairs
                        const sortedEntries = Object.entries(tips).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

                        // Convert back to object
                        const sortedTips = Object.fromEntries(sortedEntries);
                        const tipsXAxis = [];
                        const tipsSeries = [];
                        for (let [key, value] of Object.entries(sortedTips)) {
                            //console.log(`${key}: ${value}`);
                            tipsXAxis.push(key);
                            let amount = 0;
                            for (let i = 0; i < value.length; i++) {
                                amount += value[i].amountReceived - (value[i].fee ? value[i].fee : 0);
                            }
                            tipsSeries.push((amount/100).toFixed(2));
                        }
                        console.log('tipsXAxis', tipsXAxis, 'tipsSeries', tipsSeries);
                        setTipsXAxisData(tipsXAxis);
                        setTipsSeriesData(tipsSeries);
                    }
                }
                setLoaded(true);
            });
        } else {
            setLoaded(true);
        }
    }, [auth.currentUser.uid, admin, functionsInstance, firestoreInstance]);

    return (<>
        {loaded ? (
            <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} 
                    /*sx={{
                        '--Grid-borderWidth': '1px',
                        borderTop: 'var(--Grid-borderWidth) solid',
                        borderLeft: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        '& > div': {
                        borderRight: 'var(--Grid-borderWidth) solid',
                        borderBottom: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        },
                    }}*/
                >
                    <Grid size={{ xs: 12, md: 12 }}>
                        {error !== null &&
                            <Alert severity="error">{error}</Alert>
                        }
                        {prompt !== null &&
                            <Alert severity="info">
                                {prompt}
                            </Alert>
                        }
                    </Grid>
                    <Grid size={{ xs: 12, md: 8 }}>
                        <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            {admin == 'super' && (
                                    <>
                                    <Grid container spacing={0}>
                                        <Grid xs={12} md={12}>
                                            <Box sx={{p:2}}>
                                                BCBE Website Admin Console
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    </>
                            )}
                            {admin == 'manager' && (
                                <Box sx={{height: '70vh', p: 10, alignContent: 'center', textAlign: 'center'}}>
                                    Business Co-operator Access Permission
                                </Box>
                            )}
                            {admin == '' && (
                                <>
                                    Access Permission is required.
                                </>
                            )}
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="left" alignItems="center">
                        <Typography>
                        Access Level - {admin}    
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {admin == 'super' && (
                <>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 4 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                <Typography  variant="h6" gutterBottom>Earnings Overview</Typography>
                                <Typography gutterBottom>
                                    Total: {currencyFormat(subsSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue)*100, 0) + groupSubsSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue)*100, 0) + eventAccessSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue)*100, 0) + tipsSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue)*100, 0))}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 8 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            {subscriptionsData && groupSubscriptionsData && eventAccessData && tipsData && (<>
                                <PieChart
                                series={[
                                    {
                                    data: [
                                        { id: 0, value: subsSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0), label: 'Creator Subscriptions' },
                                        { id: 1, value: groupSubsSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0), label: 'Group Subscriptions' },
                                        { id: 2, value: eventAccessSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0), label: 'Events' },
                                        { id: 3, value: tipsSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0), label: 'Tips' },
                                    ],
                                    },
                                ]}
                                height={200}
                                />
                            </>)}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 4 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                <Typography  variant="h6" gutterBottom>Subscriptions</Typography>
                                <Typography gutterBottom>
                                    Total: {currencyFormat(subsSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue)*100, 0))}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 8 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            {subsXAxisData && subsSeriesData && (<>
                                <LineChart
                                xAxis={[{ scaleType: 'point', data: subsXAxisData }]}
                                series={[
                                    {
                                    data: subsSeriesData,
                                    },
                                ]}
                                height={300}
                                />
                            </>)}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 4 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                <Typography  variant="h6" gutterBottom>Group Subscriptions</Typography>
                                <Typography gutterBottom>
                                    Total: {currencyFormat(groupSubsSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue)*100, 0))}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 8 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            {groupSubsXAxisData && groupSubsSeriesData && (<>
                                <LineChart
                                xAxis={[{ scaleType: 'point', data: groupSubsXAxisData }]}
                                series={[
                                    {
                                    data: groupSubsSeriesData,
                                    },
                                ]}
                                height={300}
                                />
                            </>)}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 4 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                <Typography  variant="h6" gutterBottom>Events Access</Typography>
                                <Typography gutterBottom>
                                    Total: {currencyFormat(eventAccessSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue)*100, 0))}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 8 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            {eventAccessXAxisData && eventAccessSeriesData && (<>
                                <LineChart
                                xAxis={[{ scaleType: 'point', data: eventAccessXAxisData }]}
                                series={[
                                    {
                                    data: eventAccessSeriesData,
                                    },
                                ]}
                                height={300}
                                />
                            </>)}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 4 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                                <Typography  variant="h6" gutterBottom>Tips</Typography>
                                <Typography gutterBottom>
                                    Total: {currencyFormat(tipsSeriesData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue)*100, 0))}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 8 }}>
                            <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            {tipsXAxisData && tipsSeriesData && (<>
                                <LineChart
                                xAxis={[{ scaleType: 'point', data: tipsXAxisData }]}
                                series={[
                                    {
                                    data: tipsSeriesData,
                                    },
                                ]}
                                height={300}
                                />
                            </>)}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                </>
            )}
            </>
        ) : (
            <Box sx={{width: '100%', p: 5, textAlign:'center'}}><CircularProgress /></Box>
        )}
    </>)
}
