import React, { useContext, useEffect, useState } from "react";
import { useParams, Outlet } from 'react-router-dom';
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import { AppContext } from "../../lib/AppProvider";
import { AuthContext } from "../../lib/Auth";
import { Alert, List, ListItem, Box, Avatar, Typography, Divider, Paper, Container, styled } from "@mui/material";
import Grid from '@mui/material/Grid2';
import {BorderLinearProgress} from '../common';

export const EventPageContext = React.createContext();

export const EventPageProvider = ({children}) => {
    const { eventSlug } = useParams();
    const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(AppContext);
    const [ event, setEvent ] = useState(null);
    const [ access, setAccess ] = useState(false);
    const [ isCreator, setIsCreator ] = useState(false);

    const [loading, setLoading] = React.useState(true);

    const [ error, setError ] = useState(null);
    const [ prompt, setPrompt ] = useState(null);

    const getGroupSubscription = async (groupId) => {
        //console.log('groupId', groupId);
        let groupSub;
        const uid = authUser.user ? authUser.user.uid : null;
        if (!uid) return null;
        const groupAccessRef = collection(firestoreInstance, '/groupAccess');
        const q = query(groupAccessRef, and(where('groupId', '==', groupId), where('uid', '==', uid)));
        groupSub = await getDocs(q).then(async snapshots => {
            if (snapshots.empty) {
                return null;
            } else {
                console.log('number of subs ', snapshots.docs.length);
                // there might be cancelled subscription
                // then the user subscribed again
                let groupSubRes;
                for (let i = 0; i < snapshots.docs.length; i++) {
                    const groupAcess = snapshots.docs[i];
                    const groupAccessData = groupAcess.data();
                    console.log('groupAccessData', groupAccessData);
                    const subscriptionId = groupAccessData.subscriptionId;
                    const subDocRef = doc(firestoreInstance, "groupSubscriptions", subscriptionId);
                    const groupSub = await getDoc(subDocRef).then(docSnap => {
                        if (docSnap.exists()) {
                            const groupSubscription = docSnap.data();
                            console.log('groupSubscription', groupSubscription);
                            if (groupSubscription.subscriptionStatus == 'active' || groupSubscription.subscriptionStatus == 'trialing')
                                return {
                                        planId: groupSubscription.planId,
                                        paymentMethod: groupSubscription.paymentMethod,
                                        subscriptionId: subscriptionId,
                                        status: groupSubscription.subscriptionStatus,
                                        periodEnd: groupSubscription.subscriptionCurrentPeriodEnd
                                };
                            else return null;
                        } else {
                            return null;
                        }
                    }).catch(error => {
                        return null;
                    });
                    groupSubRes = groupSub;
                    if (groupSub && (groupSub.status == 'active' || groupSub.status == 'trialing')) {
                        break;
                    }
                }
                return groupSubRes;
            }
        });

        return groupSub;
    }

    useEffect(() => {
        setLoading(true);
        if (typeof eventSlug !== 'undefined') {
            //console.log('eventSlug', eventSlug);
            const eventRef = collection(firestoreInstance, '/events');
            const q = query(eventRef, where('slug', '==', eventSlug));
            getDocs(q).then(async snapshots => {
                if (snapshots.empty) {
                    setLoading(false);
                } else {
                    const eventData = snapshots.docs[0].data();
                    const eventId = snapshots.docs[0].id;
                    // access permission
                    //console.log('eventId', eventId);

                    //get event group info
                    const groupRef = doc(firestoreInstance, 'groups', eventData.group_id);
                    const eventGroup = await getDoc(groupRef).then(docSnap => {
                        const group = docSnap.data();
                        //console.log('group', group);
                        return group;
                    }).catch(error => {
                        //no access
                        //console.log('error', error);
                    });

                    setEvent({
                        id: eventId, eventGroup:eventGroup, ...eventData
                    });

                    if (authUser.user && eventData.creator_uid == authUser.user.uid) {
                        setAccess(true);
                        setIsCreator(true);
                    } else {
                        //1. has group access
                        const groupSub = await getGroupSubscription(eventData.group_id);
                        let hasGroupAccessPermission = false;
                        if (groupSub) {
                            if ( groupSub.planId != 'free' ) {
                                setAccess(true);
                                hasGroupAccessPermission = true;
                            }
                            //console.log('group membership', groupSub.planId);
                        }

                        //2. single event access
                        if (!hasGroupAccessPermission) {
                            const accessRef = doc(firestoreInstance, '/events/'+eventId+'/permissions/access');
                            await getDoc(accessRef).then(docSnap => {
                                //const users = docSnap.data();
                                //console.log('users', users);
                                setAccess(true);
                            }).catch(error => {
                                //no access
                                //console.log('error', error);
                            });
                        }
                    }

                    setLoading(false);
                }
            }).catch(error => {
                console.log('query error', error);
            });
        }
    },[authInstance, firestoreInstance, eventSlug]);

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} >
                <Grid size={{ xs: 12, md: 12 }}>
                    {error !== null &&
                        <Alert severity="error">{error}</Alert>
                    }
                    {prompt !== null &&
                        <Alert severity="info">
                            {prompt}
                        </Alert>
                    }
                </Grid>
            </Grid>
        </Box>
        {!loading && event ? (
            <EventPageContext.Provider value={{event, setEvent, access, setAccess, isCreator}}>
                <Outlet />
            </EventPageContext.Provider>
        ) : (<>
            {loading ? (<BorderLinearProgress />) : (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} >
                        <Grid size={{ xs: 12, md: 12 }}>
                            <Alert severity="info">
                                Event Not Found.
                            </Alert>
                        </Grid>
                    </Grid>
                </Box>
            )}
            </>
        )}
        </>
    )

}